let BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;

// if (process.env.NODE_ENV === 'development') {
//   BASE_URL = 'https://dev.srvadm.nexper.app/';
// } else if (process.env.NODE_ENV === 'production') {
//   BASE_URL = 'https://srvadm.nexper.app/';
// } else if (process.env.NODE_ENV === 'staging') {
//   BASE_URL = 'https://srvadm.nexperdev.com/';
// } else {
//   BASE_URL = 'https://srvadm.nexper.app/';  // Default to production URL if NODE_ENV is undefined or unrecognized
// }

export const urls = {
    SIGNUPS: `${BASE_URL}signups?sort=latest`,
    GET_PAYMENTS: `${BASE_URL}payouts/get-experts-due-payments`,
    GET_REFUNDS: `${BASE_URL}payouts/get-payment-refund-details`,
    PROCESS_PAYMENTS: `${BASE_URL}payouts/process-payments`,
    OPEN_TICKETS: `${BASE_URL}tickets/count/open`,
    INPROGRESS_TICKETS: `${BASE_URL}tickets/count/in-progress`,
    TODAY_TICKETS: `${BASE_URL}tickets/count/today`,
    GET_TICKETS: `${BASE_URL}tickets`,
    EXPERTS: `${BASE_URL}experts/`,
    APPOINTMENTS: `${BASE_URL}appointments`,
};
