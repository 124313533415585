// src/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
axios.defaults.withCredentials = true;

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkLoggedIn = async () => {
      const token = localStorage.getItem('authToken');

      if (token) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_ADMIN_BASE_URL}check`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setCurrentUser({});
        } catch (error) {
          localStorage.removeItem('authToken');
          setCurrentUser(null);
        }
      }
      setLoading(false);
    };

    checkLoggedIn();
  }, []);

  const signup = async (email, password) => {
    const response = await axios.post('/api/auth/signup', { email, password });
    localStorage.setItem('authToken', response.data.token);
    setCurrentUser({});
  };

  const login = async (email, password) => {
    const response = await axios.post(`${process.env.REACT_APP_ADMIN_BASE_URL}login/`, { email, password });
    localStorage.setItem('authToken', response.data.accessToken);
    setCurrentUser({});
  };

  const logout = async () => {
    try {
      // Call the logout API
      await axios.post(`${process.env.REACT_APP_ADMIN_BASE_URL}logout/`);

      // Clear localStorage and update state
      localStorage.removeItem('authToken');
      setCurrentUser(null);
    } catch (error) {
      console.error('Error logging out:', error);
      // Optionally handle error (e.g., show a notification)
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, signup, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
